import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";

const NavigationContainer = styled.ol``;

const List = styled.li`
  font-size: 11px;
  line-height: 26px;
  letter-spacing: 0.15em;

  margin: 0 20px 0 0;

  & a,
  & button {
    font-family: "soehne-breit-web-buch", "Helvetica Neue", "Lucida Grande",
      sans-serif;

    font-size: 11px;
    line-height: 26px;
    letter-spacing: 0.15em;

    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;

    transition: 250ms color ease;

    &.current {
      color: rgba(0, 0, 0, 0.5);
    }

    &:hover {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  & ol {
    position: relative;

    padding: 0;
    margin: 0 0 0 30px;

    display: ${props => props.isSubMenuVisible};

    & li {
      margin: 0;
      line-height: 22px;
    }
  }
`;
export const MobileExperienceNavigation = ({
  hideProjects,
  hideProcess,
  bgColor,
  location,
  setIsMenuOpen,
}) => {
  const [isProcessMenuOpen, setIsProcessMenuOpen] = useState(false);

  useEffect(() => {
    setIsProcessMenuOpen(false);
  }, [location]);

  const data = useStaticQuery(graphql`
    {
      prismicPage(uid: { eq: "process" }) {
        data {
          body {
            ... on PrismicPageBodyImageWithText {
              id
              primary {
                section_title {
                  text
                }
              }
              slice_type
            }
            ... on PrismicPageBodySingleImage {
              id
              slice_type
            }
            ... on PrismicPageBodyText {
              id
              slice_type
            }
            ... on PrismicPageBodyTitle {
              id
              slice_type
            }
          }
        }
      }
      prismicOverviewSection(uid: { eq: "experience" }) {
        data {
          title {
            text
          }
          sections {
            page {
              type
              document {
                ... on PrismicProjects {
                  id
                  type
                }
                ... on PrismicPress {
                  id
                  type
                }
                ... on PrismicContact {
                  id
                  type
                }
                ... on PrismicPage {
                  id
                  type
                  data {
                    title {
                      text
                    }
                    body {
                      ... on PrismicPageBodyTitle {
                        id
                        slice_type
                      }
                      ... on PrismicPageBodyText {
                        id
                        slice_type
                      }
                      ... on PrismicPageBodySingleImage {
                        id
                        slice_type
                      }
                      ... on PrismicPageBodyImageWithText {
                        id
                        slice_type
                      }
                    }
                  }
                }
                ... on PrismicProjects {
                  id
                  data {
                    title {
                      text
                    }
                    projects {
                      project {
                        url
                        document {
                          ... on PrismicProject {
                            id
                            data {
                              title {
                                text
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const processNavigation = data.prismicPage.data.body.map((page, index) => {
    if (page.slice_type === "image_with_text") {
      return (
        <List
          key={`single_process_nav_item_${index}`}
          onClick={() => {
            setIsMenuOpen(false);
            document.querySelector(`#item-${page.id}`).scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }}
        >
          {page.primary.section_title.text}
        </List>
      );
    }
  });

  const navigation = data.prismicOverviewSection.data.sections.map(
    (page, index) => {
      if (page.page.document.data.title.text === "Projects") {
        return null;
      } else if (page.page.document.data.title.text === "Process") {
        return (
          <List
            key={`single_nav_item_${page.page.document.id}_${index}`}
            className="uppercase"
            bgColor={bgColor}
          >
            <button
              className="uppercase"
              onClick={() => {
                setIsProcessMenuOpen(!isProcessMenuOpen);
              }}
            >
              {page.page.document.data.title.text}
            </button>
            {isProcessMenuOpen && <ol>{processNavigation}</ol>}
          </List>
        );
      } else if (page.page.document.data.title.text === "Philosophy") {
        const allPhilosophySlideIds = page.page.document.data.body.map(
          item => item.id
        );
        const firstPhilosophySlideId = allPhilosophySlideIds[0];

        return (
          <List
            key={`single_nav_item_${page.page.document.id}_${index}`}
            className="uppercase"
            onClick={() => {
              document
                .querySelector(`#item-${firstPhilosophySlideId}`)
                .scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
            }}
          >
            {page.page.document.data.title.text}
          </List>
        );
      }
    }
  );

  return (
    <NavigationContainer className="sub-navigation">
      {navigation}
    </NavigationContainer>
  );
};

import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";

const NavigationContainer = styled.ol``;

const List = styled.li`
  font-size: 11px;
  line-height: 26px;
  letter-spacing: 0.15em;

  margin: 0 20px 0 0;

  & a,
  & button {
    font-family: "soehne-breit-web-buch", "Helvetica Neue", "Lucida Grande",
      sans-serif;

    font-size: 11px;
    line-height: 26px;
    letter-spacing: 0.15em;

    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;

    transition: 250ms color ease;

    &.current {
      color: rgba(0, 0, 0, 0.5);
    }

    &:hover {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  & ol {
    position: relative;

    padding: 0;
    margin: 0 0 0 30px;

    & li {
      margin: 0;
      line-height: 22px;
    }
  }
`;

export const MobileProjectsNavigation = ({ location }) => {
  const [isProjectsMenuOpen, setIsProjectsMenuOpen] = useState(false);

  useEffect(() => {
    setIsProjectsMenuOpen(false);
  }, [location]);

  const data = useStaticQuery(graphql`
    {
      prismicProjects {
        data {
          projects {
            project {
              document {
                ... on PrismicProject {
                  id
                  data {
                    title {
                      text
                    }
                  }
                  url
                }
              }
            }
          }
        }
      }
    }
  `);

  const projectsMenu = data.prismicProjects.data.projects.map(
    (project, index) => (
      <List key={`single_sub_nav_item_${project.project.document.id}_${index}`}>
        <Link to={project.project.document.url} activeClassName="current">
          {project.project.document.data.title.text}
        </Link>
      </List>
    )
  );

  return (
    <NavigationContainer className="sub-navigation">
      {projectsMenu}
    </NavigationContainer>
  );
};

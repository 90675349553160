import React, { useEffect, useState, useRef } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { useClickAway } from "react-use";

// Components
import { MobileProjectsNavigation } from "./mobile-projects-navigation";
import { MobileDesignNavigation } from "./mobile-design-navigation";
import { MobileExperienceNavigation } from "./mobile-experience-navigation";

// Icons
import { Toggle } from "../icons/menu-toggle";

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  padding: 25px 40px;

  @media (max-width: 767px) {
    padding: 0;
  }

  z-index: 200;
  color: ${props => props.textColor};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  & ol.studio-link {
    padding: 0;
    margin: 0 0 0 auto;

    & li {
      font-size: 13px;
      line-height: 22px;
      letter-spacing: 0.15em;

      & a {
        font-family: "soehne-breit-web-buch", "Helvetica Neue", "Lucida Grande",
          sans-serif;

        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
        text-rendering: optimizeLegibility !important;
        -webkit-font-smoothing: antialiased !important;

        transition: 250ms color ease;

        &.current {
          color: ${props => props.hoverTextColor};
        }

        &:hover {
          color: ${props => props.hoverTextColor};
        }

        @media (hover: none) {
          &:hover {
            color: ${props => props.textColor};
          }
        }
      }
    }
  }
`;

const Nav = styled.nav`
  padding: 22px 30px;

  &.menu-is-open {
    padding: 22px 30px 17px 30px;
  }

  width: 100%;

  transition: 500ms background-color ease;
  background-color: ${props => props.bgColor};
  opacity: 0.9;

  font-size: 11px;
  line-height: 1;
  letter-spacing: 0.15em;

  font-family: "soehne-breit-web-buch", "Helvetica Neue", "Lucida Grande",
    sans-serif;

  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;

  & .mobile-menu-closed-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;

    & p {
      margin: 0;
    }
  }

  & ol.mobile-navigation {
    width: 100%;

    & li {
      width: 100%;

      font-size: 11px;
      line-height: 26px;
      letter-spacing: 0.15em;

      & .sub-navigation {
        margin: 0 0 25px 30px;
      }

      &.contact-link-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      & a {
        font-family: "soehne-breit-web-buch", "Helvetica Neue", "Lucida Grande",
          sans-serif;

        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
        text-rendering: optimizeLegibility !important;
        -webkit-font-smoothing: antialiased !important;

        transition: 250ms color ease;

        &.current {
          color: ${props => props.hoverTextColor};
        }

        &:hover {
          color: ${props => props.hoverTextColor};
        }

        @media (hover: none) {
          &:hover {
            color: ${props => props.textColor};
          }
        }
      }
    }
  }
`;

export const MobileNavigation = ({
  pageType,
  location,
  bgColor,
  textColor,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const ref = useRef(null);
  useClickAway(ref, () => {
    setIsMenuOpen(false);
  });

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  return (
    <Wrapper
      ref={ref}
      bgColor={bgColor}
      textColor={textColor}
      hoverTextColor={
        pageType === `Studio` ? `rgba(248,241,232,0.5)` : `rgba(0, 0, 0, 0.5)`
      }
    >
      <Nav
        bgColor={bgColor}
        className={isMenuOpen ? `menu-is-open` : ``}
        hoverTextColor={
          pageType === `Studio` ? `rgba(248,241,232,0.5)` : `rgba(0, 0, 0, 0.5)`
        }
        textColor={textColor}
      >
        {!isMenuOpen && (
          <div className="mobile-menu-closed-container">
            <button onClick={() => setIsMenuOpen(true)}>
              <Toggle isOpen={isMenuOpen} fill={textColor} />
            </button>

            {pageType === `Projects` && (
              <p className="uppercase active-section-title">Projects</p>
            )}

            {pageType === `Design` && (
              <p className="uppercase active-section-title">
                <Link to={`/projects`}>Projects</Link>
              </p>
            )}
            {pageType === `Experience` && (
              <p className="uppercase active-section-title">
                <Link to={`/projects`}>Projects</Link>
              </p>
            )}
            {pageType === `Studio` && (
              <p className="uppercase active-section-title">Studio</p>
            )}
          </div>
        )}

        {isMenuOpen && (
          <div className="mobile-menu-open-container">
            <ol className="mobile-navigation">
              <li className="uppercase">
                <Link
                  to={`/design`}
                  className={pageType === `Design` ? `current` : ``}
                >
                  Design
                </Link>
                {pageType === `Design` && (
                  <MobileDesignNavigation
                    hideProjects={location.includes("/projects")}
                    hideDesignElements={location.includes("/elements")}
                    location={location}
                    setIsMenuOpen={setIsMenuOpen}
                  />
                )}
              </li>
              <li className="uppercase">
                <Link
                  to={`/experience`}
                  className={pageType === `Experience` ? `current` : ``}
                >
                  Experience
                </Link>
                {pageType === `Experience` && (
                  <MobileExperienceNavigation
                    hideProjects={location.includes("/projects")}
                    hideProcess={location.includes("/process")}
                    location={location}
                    setIsMenuOpen={setIsMenuOpen}
                  />
                )}
              </li>

              <li className="uppercase">
                <Link
                  to={`/projects`}
                  className={pageType === `Projects` ? `current` : ``}
                >
                  Projects
                </Link>
                {pageType === `Projects` && (
                  <MobileProjectsNavigation
                    hideProjects={location.includes("/projects")}
                    hideProcess={location.includes("/process")}
                    location={location}
                  />
                )}
              </li>

              <li className="uppercase">
                <Link
                  to={`/studio`}
                  className={pageType === `Studio` ? `current` : ``}
                >
                  Studio
                </Link>
              </li>

              <li className="uppercase contact-link-container">
                <Link
                  to={`/studio#contact`}
                  className={pageType === `Studio` ? `current` : ``}
                >
                  Contact
                </Link>

                <button onClick={() => setIsMenuOpen(false)}>
                  <Toggle isOpen={isMenuOpen} fill={textColor} />
                </button>
              </li>
            </ol>
          </div>
        )}
      </Nav>
    </Wrapper>
  );
};

import React, { useContext, useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageBackgroundColor } from "../components/context/page-background-color";
import { PageType } from "../components/context/page-type";

// Components
import { MobileNavigation } from "../components/navigation/mobile-navigation";
import { AspectRatioImageContainer } from "../components/containers/aspect-ratio-image-container";

const Page = styled.div`
  padding: 150px 40px 100px 40px;

  @media (max-width: 767px) {
    padding: 65px 0;
  }
`;

const HeaderBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  height: 75px;

  background-color: #fffdfa;
  z-index: 10;

  @media (max-width: 767px) {
    background-color: #f8f1e8;
    height: 65px;
  }
`;

const FooterBar = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  height: 73px;

  background-color: #fffdfa;
  z-index: 10;

  @media (max-width: 767px) {
    background-color: #f8f1e8;
    height: 66px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;

  @media (max-width: 767px) {
    grid-row-gap: 30px;
  }
`;

const SingleProject = styled.div`
  &.width-50 {
    grid-column: span 1;

    @media (max-width: 767px) {
      grid-column: span 2;
    }
  }

  &.width-100 {
    grid-column: span 2;
  }

  & .image-container {
    overflow: hidden;

    & .inner-image-container {
      position: relative;
      width: 100%;
      height: 100%;

      & img {
        width: 100%;
        height: 100%;

        position: relative;

        object-fit: cover;

        transition: 250ms opacity ease;
        z-index: 1;

        &.rollover-image {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 0;

          object-fit: cover;
        }
      }
    }
  }

  & .text-container {
    width: 100%;
    text-align: center;

    margin: 30px 0 0 0;

    @media (max-width: 767px) {
      margin: 12px 0 0 0;
    }

    & h2 {
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0.15em;

      display: block;

      @media (max-width: 767px) {
        font-size: 11px;

        font-family: "soehne-breit-web-buch", "Helvetica Neue", "Lucida Grande",
          sans-serif;
      }
    }

    & .tagline {
      display: none;

      & p {
        font-size: 14px;
        line-height: 26px;
        letter-spacing: 0.02em;

        margin: 0;
      }
    }
  }

  &.sepia {
    & img {
      transition: 250ms all ease !important;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      & .image-container {
        & .inner-image-container {
          & img.thumbnail {
            opacity: 0.3;
          }

          & img.rollover-image {
            z-index: 2;
            mix-blend-mode: multiply;
          }
        }
      }

      // &.sepia {
      //   & .image-container {
      //     & .inner-image-container {
      //       & img.thumbnail {
      //         opacity: 0.4;
      //         filter: sepia(1);
      //       }

      //       & img.rollover-image {
      //         display: none;
      //         // z-index: 2;
      //         // mix-blend-mode: multiply;
      //       }
      //     }
      //   }
      // }
    }
  }
`;

const Projects = ({ data, location }) => {
  let isDesktop = useMediaQuery("(min-width: 768px)");

  const [pageBackgroundColor, setPageBackgroundColor] = useContext(
    PageBackgroundColor
  );
  const [pageType, setPageType] = useContext(PageType);

  useEffect(() => {
    if (isDesktop) {
      setPageBackgroundColor(`#FFFDFA`);
    } else {
      setPageBackgroundColor(`#F8F1E8`);
    }
  }, [isDesktop]);

  useEffect(() => {
    setPageType(`Projects`);
  }, [data]);

  const content = data.prismicProjects.data.projects
    .filter(project => project.project.document !== null)
    .map((project, index) => (
      <SingleProject
        className={`width-${project.project_width.slice(0, -1)} ${
          index === 1 ? `sepia` : ``
        }`}
        key={`single_project_${index}_${project.project.document.id}`}
      >
        <Link to={project.project.url}>
          <div className="image-container">
            {project.project.document.data.image.fluid !== null && (
              <AspectRatioImageContainer
                image={null}
                padding={isDesktop ? 66.667 : 100}
              >
                <div className="inner-image-container">
                  <img
                    className="rollover-image"
                    srcSet={
                      data.prismicProjects.data.rollover_texture.fluid
                        .srcSetWebp
                    }
                    src={
                      data.prismicProjects.data.rollover_texture.fluid.srcWebp
                    }
                    alt={data.prismicProjects.data.rollover_texture.alt}
                    loading={`lazy`}
                  />

                  <img
                    className="thumbnail"
                    srcSet={
                      project.project.document.data.image.fluid.srcSetWebp
                    }
                    src={project.project.document.data.image.fluid.srcWebp}
                    alt={project.project.document.data.image.alt}
                    loading={`lazy`}
                  />
                </div>
              </AspectRatioImageContainer>
            )}
          </div>
          <div className="text-container">
            <h2 className="uppercase soehne-light">
              {project.project.document.data.title.text}
            </h2>
          </div>
        </Link>
      </SingleProject>
    ));

  return (
    <>
      <Helmet
        title={`Projects | Dan Fink Studio`}
        meta={[
          {
            name: "title",
            content: `Projects | Dan Fink Studio`,
          },
          {
            property: "og:title",
            content: `Projects | Dan Fink Studio`,
          },
          {
            property: "twitter:title",
            content: `Projects | Dan Fink Studio`,
          },
          {
            property: "og:url",
            content: `https://danfinkstudio.com${data.prismicProjects.url}`,
          },
          {
            property: "twitter:url",
            content: `https://danfinkstudio.com${data.prismicProjects.url}`,
          },
        ]}
      />
      <Page>
        <HeaderBar />
        <Grid>{content}</Grid>
        <FooterBar>
          {!isDesktop && (
            <MobileNavigation
              pageType={pageType}
              location={location.pathname}
              bgColor={`#f8f1e8`}
              textColor={`#000`}
            />
          )}
        </FooterBar>
      </Page>
    </>
  );
};

export default withPreview(Projects);

export const query = graphql`
  {
    prismicProjects {
      _previewable
      url
      data {
        rollover_texture {
          alt
          fluid {
            srcWebp
            srcSetWebp
          }
        }
        projects {
          project_width
          project {
            document {
              ... on PrismicProject {
                id
                data {
                  title {
                    text
                  }
                  image {
                    alt
                    fluid {
                      srcWebp
                      srcSetWebp
                    }
                  }
                }
              }
            }
            url
          }
        }
      }
    }
  }
`;

import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
  transform: rotate(${props => props.rotate});
  transition: 150ms transform ease;
`;

export const Toggle = ({ isOpen, fill }) => (
  <Icon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    rotate={isOpen ? `45deg` : `0deg`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5003 9.50024L10.5003 0H9.50031L9.50031 9.50024L0 9.50024V10.5002L9.50031 10.5002L9.50031 20H10.5003L10.5003 10.5002L20 10.5002V9.50024L10.5003 9.50024Z"
      fill={fill}
    />
  </Icon>
);
